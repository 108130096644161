* {
  font-family: "Noto Sans TC", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.text-underline_bold {
  text-decoration: underline;
  font-weight: bold;
}

.nav-link {
  font-size: 20px;
}

.no-bullets {
  list-style-type: none;
}

.form-section .form-group label {
  font-size: 20px;
}

.ant-form-item-label label {
  font-size: 20px;
}

.ant-input {
  font-size: 20px;
}

.ant-input-group-addon {
  font-size: 20px;
}

.ant-select-selection-placeholder {
  color: black;
}

ant-table-cell {
  font-size: 20px;
}

Table {
  font-size: 20px;
}

#btnScrollToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

#btnScrollToTop:hover {
  background-color: #0056b3;
}
